import React, { Component } from 'react';
import './index.css';

import IMG from './csloader.gif';

class CustomLoader extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}			
	}
	
	
	render(){
		return(
			<div className="circle-slow-loaders">
				<center>
					<img src={IMG} />
					<p>Loading...</p>
				</center>
			</div>
		);
	}
	
}
export default CustomLoader;

const COOKIE_KEY = 'vai65';

/* const Api = {
	Url :'http://localhost/kalvisalai/hopeschool/api/',
	ImageUrl: 'http://localhost/kalvisalai/hopeschool/api', //Dont put slash in last
	SiteUrl: 'http://localhost/kalvisalai/hopeschool/',
	AcademicPath: ''
}; */

const Api = {
	Url :'https://thehopematrichss.edu.in/api/',
	ImageUrl: 'https://thehopematrichss.edu.in/api', //Dont put slash in last
	SiteUrl: 'https://thehopematrichss.edu.in/',
	AcademicPath: ''
};



const Var = {
	Title: 'WebSite',
	Site: 'https://peacesoft.in/',
	Phone: '6379705440',
	Email : 'peacesoft.developer@gmail.com',
	Address: '',
	City: 'Tirunelveli',
	Pincode: ' 627002',
	RegularCourseFeeForScSt: 0,
	RegularCourseFeeForOthers : 50,
	SelfCourseFeeForScSt: 50,
	SelfCourseFeeForOthers : 50,
	EnableCashPayment: true,
	EnableOnlinePayment: true,
	AdminPath: 'webadmin',
};

const MonthData = [
	{id: 1, name: 'January'},
	{id: 2, name: 'February'},
	{id: 3, name: 'March'},
	{id: 4, name: 'April'},
	{id: 5, name: 'May'},
	{id: 6, name: 'June'},
	{id: 7, name: 'July'},
	{id: 8, name: 'August'},
	{id: 9, name: 'September'},
	{id: 10, name: 'October'},
	{id: 11, name: 'November'},
	{id: 12, name: 'December'},
]

const AppList = [
	{id: 1, key: 'examaid', name: 'Exam-Aid'}
]

const classList = [
	{id: 1, key: 'Pre-KG', name:'PRE-KG'},
	{id: 2, key: 'L-KG', name:'L-KG'},
	{id: 3, key: 'U-KG', name:'U-KG'},
	{id: 4, key: 'I', name:'I STD'},
	{id: 5, key: 'II', name:'II STD'},
	{id: 6, key: 'III', name:'III STD'},
	{id: 7, key: 'IV', name:'IV STD'},
	{id: 8, key: 'V', name:'V STD'},
	{id: 9, key: 'V', name:'VI STD'},
	{id: 10, key: 'V', name:'VII STD'},
	{id: 11, key: 'V', name:'VIII STD'},
	{id: 12, key: 'V', name:'IX STD'},
];

export {
	COOKIE_KEY,
	Api,
	Var,
	MonthData,
	AppList,
	classList,
};

import {Var} from '../utils/Variables';

export default {
	top: [
			{ name: 'Dashboard', url: '/'+Var.AdminPath+'/app', icon: 'Home', ionicon: 'mdi mdi-home', exact: true},
			//{ name: 'Applications', url: '/'+Var.AdminPath+'/app/online-admission/applications', icon: 'Home', ionicon: 'mdi mdi-book-open-outline', allowed:['webadmin']},
			{ name: 'Website CMS', icon: 'File', ionicon: 'mdi mdi-book-open-outline',allowed: ['webadmin'], 
			  children: [
				{ name: 'Announcements', url: '/'+Var.AdminPath+'/app/cms/announcement', icon: 'Home', ionicon: 'ion-minuss',  allowed:['webadmin', 'siteadmin'] },
				{ name: 'Pages', url: '/'+Var.AdminPath+'/app/cms/pages', icon: 'Home', ionicon: 'ion-minuss',  allowed:['webadmin', 'siteadmin'] },
				{ name: 'Events', url: '/'+Var.AdminPath+'/app/cms/events', icon: 'Home', ionicon: 'ion-minuss',  allowed:['webadmin', 'siteadmin'] },
				{ name: 'Gallery', url: '/'+Var.AdminPath+'/app/cms/gallery', icon: 'Home', ionicon: 'ion-minuss',  allowed:['webadmin', 'siteadmin'] },
				{ name: 'Sliders', url: '/'+Var.AdminPath+'/app/cms/sliders', icon: 'Home', ionicon: 'ion-ios7-albumss',  allowed:['webadmin', 'siteadmin'] },
				{ name: 'Menus', url: '/'+Var.AdminPath+'/app/cms/menus', icon: 'Home', ionicon: 'ion-drags',  allowed:['webadmin', 'siteadmin'] },
				{ name: 'Home Page', url: '/'+Var.AdminPath+'/app/cms/static/homepage', icon: 'Home', ionicon: 'ion-drags',  allowed:['webadmin', 'siteadmin'] },
				{ name: 'Sidebar', url: '/'+Var.AdminPath+'/app/cms/static/sidebar', icon: 'Home', ionicon: 'ion-drags',  allowed:['webadmin', 'siteadmin'] },
			  ],
			},
			{ name: 'File Manager', url: '/'+Var.AdminPath+'/app/filemanager', icon: 'Home', ionicon: 'mdi mdi-folder-open-outline'},
			{ name: 'Settings', url: '/'+Var.AdminPath+'/app/cms/settings', icon: 'Home', ionicon: 'mdi mdi-card-bulleted-settings-outline'},
		],
	bottom: [
	]
}
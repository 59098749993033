import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';

import LOGO from '../../../assets/img/logo.png';

import StaticData from '../../pages/Dashboard/StaticData';


class Footer extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
		}		
	}
	
	componentDidMount(){
		
		this.loadMenus();
	}
	
	loadMenus(){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/site/footer-menus').then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	isNewLink(val){
		try{
			if(val.substr(0,4)=='http')
				return true;
			
			return false;
		}
		catch(error){
			console.log(error);
			return false;
		}
	}
	
	getLinks(){
		var rv = [];
		try{
			this.state.dataList.map((item,i)=>{
				if(this.isNewLink(item.menulink)){
					rv.push(<li>
						<a activeClassName="active" to={"/"+item.menulink} target={item.open_in_newtab=='1' ? '_blank' : ''} >{item.title}</a>
					</li>);
				}
				else{
					rv.push(<li>
						<Link activeClassName="active" to={"/"+item.menulink} target={item.open_in_newtab=='1' ? '_blank' : ''} >
							<i className="mdi mdi-menu-right" aria-hidden="true"></i>  {item.title}
						</Link>
					</li>);
				}
			});
			return rv;
		}
		catch(error){
			return rv;
		}
		
	}
	
	render(){
		
		return(
			 <footer id="rs-footer" className="bg8 blue-bg rs-footer">
				<div className="blue-overlay"></div>
			{/*	<div className="container">
					<div>
						<div className="row footer-contact-desc">
							<div className="col-md-4">
								<div className="contact-inner">
									<i className="mdi mdi-map-marker"></i>
									<h4 className="contact-title">Address</h4>
									<p className="contact-desc">
										{getls('site','address')}<br />
										{getls('site','city')}, {getls('site','state')}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="contact-inner">
									<i className="mdi mdi-phone"></i>
									<h4 className="contact-title">Phone Number</h4>
									<p className="contact-desc">
										{getls('site','mobile')}<br />
										{getls('site','phone')}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="contact-inner">
									<i className="mdi mdi-email"></i>
									<h4 className="contact-title">Email Address</h4>
									<p className="contact-desc">
										{getls('site','email')}
									</p>
								</div>
							</div>
						</div>					
					</div>
			 </div>*/}
				
			  <div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 col-md-12">
								<div className="about-widget">
									<img src={Api.ImageUrl + '/'+ getls('site','logo')} alt={getls('site','sitemeta')} />
									<StaticData dataKey="homepage_history" />
								</div>
							</div>
							
							{/*<div className="col-lg-3 col-md-12">
								<h5 className="footer-title">RECENT POSTS</h5>
								<div className="recent-post-widget">
									<div className="post-item">
										<div className="post-date">
											<span>28</span>
											<span>June</span>
										</div>
										<div className="post-desc">
											<h5 className="post-title"><a href="#">While the lovely valley team work</a></h5>
											<span className="post-category">Keyword Analysis</span>
										</div>
									</div>
									<div className="post-item">
										<div className="post-date">
											<span>28</span>
											<span>June</span>
										</div>
										<div className="post-desc">
											<h5 className="post-title"><a href="#">I must explain to you how all this idea</a></h5>
											<span className="post-category">Spoken English</span>
										</div>
									</div>
								</div>
							</div>*/}

							<div className="col-lg-3 col-md-12">
								<h5 className="footer-title">Quick Links</h5>
								<ul className="sitemap-widget">
									{this.getLinks()}
								</ul>
							</div>
							<div className="col-lg-2 col-md-12">
								<h5 className="footer-title">CONTACT INFO</h5>
								<ul>
									<li><i className="mdi mdi-map-marker" /> {getls('site','address')}</li>
									<li><i className="mdi mdi-map-marker" /> {getls('site','city')}</li>
									<li><i className="mdi mdi-map-marker" /> {getls('site','state')} - {getls('site','pincode')}</li>
									<li><i className="mdi mdi-cellphone-android" />  {getls('site','mobile')}</li>
									<li><i className="mdi mdi-phone" /> {getls('site','phone')}</li>
									<li><i className="mdi mdi-email" />  {getls('site','email')}</li>
								</ul>
							</div>
							<div className="col-lg-4 col-md-12">
								<h5 className="footer-title">MAP</h5>
								<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15779.752725989887!2d77.5667495!3d8.6019341!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x35b8112be2f001bf!2sThe%20Hope%20Matric%20Higher%20Secondary%20School!5e0!3m2!1sen!2sin!4v1616495808812!5m2!1sen!2sin" width="400" height="300" style={{border:'0'}} allowfullscreen="" loading="lazy"></iframe>
							</div>
						</div>
						{/*<div className="footer-share">
							<ul>
								<li><a href={getls('site','facebook')} target="_blank" ><i className="mdi mdi-facebook"></i></a></li>
								<li><a href={getls('site','gplus')} target="_blank" ><i className="mdi mdi-google-plus"></i></a></li>
								<li><a href={getls('site','twitter')} target="_blank" ><i className="mdi mdi-twitter"></i></a></li>
								<li><a href={getls('site','youtube')} target="_blank" ><i className="mdi mdi-youtube"></i></a></li>
							</ul>
						</div>*/}                             
					</div>
				</div>
				
				<div className="footer-bottom">
						<div className="container">
							<div className="copyright">
							<p>&copy; 2020 <Link to="/">{getls('site','sitetitle')}</Link>. All Rights Reserved.
							Design by <a href="https://peacesoft.in" target="_blank">Peace Soft</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		);
	}
	
}
export default Footer;

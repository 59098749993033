import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import {LineLoader, SiteSlider, CircleSlowLoader} from '../../elements';
import API from '../../../utils/API';

import PageHeader from './PageHeader';
import RightSidebar from './RightSidebar';

import Events from '../Dashboard/Events';

class Event extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			pageUrl: '',
			pageData: [],
		}
	}
	
	componentDidMount(){
		
		this.state.showLoader = true;
		console.log(this.props.match.params);
		//this.state.pageUrl = this.props.match.params[0].replace(/^\/|\/$/g, '');
		this.state.pageUrl = this.props.match.params.slug;
		this.loadPageContent();
	}
	
	componentWillReceiveProps(props){
		
		//this.state.pageUrl = props.match.params[0].replace(/^\/|\/$/g, '');
		this.state.pageUrl = this.props.match.params.slug;
		
		this.loadPageContent();
		//this.forceUpdate();
	}
	
	loadPageContent(){
		try{
			
			this.setState({showLoader:true, pageData: []});
			API.get('v1/site/news/'+this.state.pageUrl).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({pageData: res['data'].data, showLoader:false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getField(fieldName){
		try{
			var d = this.state.pageData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	render(){

		return(
			<React.Fragment>
			
			{this.state.showLoader ? <CircleSlowLoader loading={true} /> : 
				 <>
				
				<PageHeader pageTitle={this.getField('title')} />
				
				
					<section>
						<div className="container py-50" >
							<Row>
								<Col md={8} >
									<div dangerouslySetInnerHTML={{__html: this.getField('content')}} />
								</Col>
								<Col md={4} >
									<Events />
								</Col>
							</Row>
						</div>
					</section>
				
				
			</>
			}
			</React.Fragment>
		);
	}
	
}
export default Event;
